<template>
  <div class="indexbox">
    <div style="margin-bottom: 8px">
      <div class="indexBoxLeft">
        <div class="realtime">
          <div class="realtimebot">
            <div class="listItem">
              <div class="topItem" style="width: 15%">
                <div class="today">
                  <p style="margin-top: 10px; margin-bottom: 10px; font-size: 14px">今日总佣金（元）</p>
                  <p style="color: red; text-align: center">{{ total.today_total_amount }}</p>
                  <div class="computed" style="font-size: 14px">
                    <span>昨日 {{ total.yesterday_total_amount }}</span>
                    <div>
                      <span style="color: red" v-if="total.today_total_amount < total.yesterday_total_amount">↓</span>
                      <span style="color: red" v-else-if="total.today_total_amount == total.yesterday_total_amount"></span>
                      <span style="color: green" v-else>↑</span>
                      <span v-if="total.today_total_amount < total.yesterday_total_amount">{{ 100 - parseInt((total.today_total_amount / total.yesterday_total_amount) * 100) }}%</span>
                      <span v-else-if="total.today_total_amount == total.yesterday_total_amount">0%</span>
                      <span v-else>{{ parseInt((total.today_total_amount / total.yesterday_total_amount) * 100) }}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 84%">
                <div class="topItem" v-for="(item, index) in realData" :key="index" style="width: 15%; margin-bottom: 20px">
                  <div class="today" style="width: 87%">
                    <p style="margin-top: 10px; margin-bottom: 10px; font-size: 14px">
                      {{ item.name }}
                    </p>
                    <p style="text-align: center">{{ item.data }}</p>
                    <div class="computed" style="font-size: 14px">
                      <span>昨日 {{ item.dataYesterday }}</span>
                      <div>
                        <span style="color: red" v-if="item.data < item.dataYesterday">↓</span>
                        <span style="color: red" v-else-if="item.data == item.dataYesterday"></span>
                        <span style="color: green" v-else>↑</span>

                        <span v-if="item.data == 0 && item.dataYesterday == 0">0%</span>
                        <span v-else-if="item.dataYesterday == 0">{{ parseInt(item.data * 100) }}%</span>
                        <span v-else-if="item.data == 0">{{ parseInt(item.dataYesterday * 100) }}%</span>

                        <span v-else-if="item.data < item.dataYesterday">{{ 100 - parseInt((item.data / item.dataYesterday) * 100) }}%</span>
                        <span v-else-if="item.data == item.dataYesterday">1%</span>
                        <span v-else>{{ parseInt((item.data / item.dataYesterday) * 100) }}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="charts">
      <div class="realtimetop">
        <p class="title">今日收益排行榜</p>
      </div>
      <div class="block">
        <span>查询日期：</span>
        <el-date-picker @change="changeDatatime" v-model="value2" type="date" placeholder="选择日期时间" align="right" :picker-options="pickerOptions"></el-date-picker>
      </div>
    </div>
    <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <!-- <template slot="empty">
        <No />
      </template> -->
      <el-table-column prop="mobile" label="运营商姓名" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.facilitator.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="授权区域" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.area_name ? scope.row.province_name + scope.row.city_name + scope.row.area_name : scope.row.province_name + scope.row.city_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft('all', form.order_type)">
            <div class="label">今日收益金额</div>
            <div class="px">
              <i :style="{ color: !form.type && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: !form.type && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(1, form.order_type)">
            <div class="label">今日wifi佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 1 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 1 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_wifi_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="今日扫码佣金" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_scan_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(2, form.order_type)">
            <div class="label">今日第三方佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 2 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 2 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_activity_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(7, form.order_type)">
            <div class="label">今日激励佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 7 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 7 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_activity_send_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(6, form.order_type)">
            <div class="label">今日商户激励佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 6 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 6 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_link_send_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(5, form.order_type)">
            <div class="label">今日到店支付费率佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 5 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 5 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_pay_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(8, form.order_type)">
            <div class="label">今日CPS商品分润佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 8 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 8 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_cps_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(10, form.order_type)">
            <div class="label">今日话费慢充佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 10 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 10 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_tel_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(20, form.order_type)">
            <div class="label">今日话费快充佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 20 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 20 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_tel_fa_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(30, form.order_type)">
            <div class="label">今日吃喝卡券佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 30 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 30 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_coupon_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" min-width="120" align="center">
        <template #header>
          <div class="header" @click="getMidLeft(40, form.order_type)">
            <div class="label">今日话费佣金</div>
            <div class="px">
              <i :style="{ color: form.type == 40 && form.order_type == 2 ? '#5cb6ff' : '' }" class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom" :style="{ color: form.type == 40 && form.order_type == 1 ? '#5cb6ff' : '' }"></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ Math.floor(scope.row.today_power_total_amount * 100) / 100 }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="totalNum" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </div>
</template>
   
<script>
import _ from 'lodash';
import Paging from '@/components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      realData: [],
      total: {},
      totalNum: {},
      form: {
        page: 1,
        rows: 10,
        order_type: 1,
      },
      page: 1,
      rows: 10,
      userList: [],
      amount_type: [
        {
          val: 'total_price',
          name: '今日wifi佣金（元）',
          color: 'orange',
        },
        {
          val: 'deposit_price',
          name: '服务订单总金额',
          color: '#F86200',
        },
        {
          val: 'final_pay_price',
          name: '服务结算总金额',
          color: 'orange',
        },
        {
          val: 'fianl_reduce_price',
          name: '服务总订单数',
          color: 'red',
        },
      ],

      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      value2: '',
    };
  },
  created() {
    this.getIndexData();
    this.getDateData();
  },
  methods: {
    changeDatatime() {
      this.getDateData(this.value2.getTime() / 1000);
    },
    getIndexData() {
      this.$axios.post(this.$api.set.statisticalData).then(res => {
        if (res.code == 0) {
          let result = res.result;
          this.total.today_total_amount = Math.floor(res.result.today_total_amount * 100) / 100;
          this.total.yesterday_total_amount = Math.floor(res.result.yesterday_total_amount * 100) / 100;
          this.realData = [
            {
              name: '今日新增商家数（个）',
              data: Math.floor(result.today_store_update_num),
              dataYesterday: Math.floor(result.yesterday_store_update_num),
              path: '/finance/accounts',
            },
            {
              name: '今日wifi佣金（元）',
              data: Math.floor(result.today_wifi_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_wifi_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日扫码点餐佣金（元）',
              data: Math.floor(result.today_scan_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_scan_total_amount * 100) / 100,
              path: '/finance/accounts',
            },

            {
              name: '今日第三方佣金（元）',
              data: Math.floor(result.today_activity_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_activity_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日激励佣金（元）',
              data: Math.floor(result.today_activity_send_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_activity_send_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日商户激励佣金（元）',
              data: Math.floor(result.today_link_send_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_link_send_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日到店支付费率佣金（元）',
              data: Math.floor(result.today_pay_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_pay_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日CPS商品分润佣金（元）',
              data: Math.floor(result.today_cps_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_cps_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日话费慢充佣金（元）',
              data: Math.floor(result.today_tel_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_tel_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日话费快充佣金（元）',
              data: Math.floor(result.today_tel_fa_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_fa_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日吃喝卡券佣金（元）',
              data: Math.floor(result.today_coupon_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_coupon_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
            {
              name: '今日电费佣金（元）',
              data: Math.floor(result.today_power_total_amount * 100) / 100,
              dataYesterday: Math.floor(result.yesterday_power_total_amount * 100) / 100,
              path: '/finance/accounts',
            },
          ];
        }
      });
    },
    getDateData(val, type, order_type) {

      if (val == 'all') {
       this.form.order_type = type;
      } else if (type == 'all') {
        this.form.start_time = val;
        this.form.end_time = val + 3600 * 24;
       this.form.order_type = order_type;
      } else if (val && type && order_type) {
        this.form.start_time = val;
        this.form.end_time = val + 3600 * 24;
        this.form.type = type;
        this.form.order_type = order_type;
      } else if (val && type) {
        this.form.type = val;
        this.form.order_type = type;
      } else {
        this.form.start_time = val;
        this.form.end_time = val + 3600 * 24;
      }

      this.$axios.post(this.$api.set.statisticalDataData, this.form).then(res => {
        if (res.code == 0) {
          this.userList = res.result.list;
          this.totalNum = res.result.total;
        } else {
          this.$message.error('处理失败');
        }
      });
    },
    // 下一页
    updateData(val, status) {
      if(this.value2) {
        this.getDateData(this.value2.getTime() / 1000);
      } else if (status == 0) {
        this.form.rows = val;
        this.getDateData();
      } else {
        this.form.page = val;
        this.getDateData();
      }
    },
     
    getMidLeft(type, order_type) {
      if (order_type == 1) {
        order_type = 2;
        console.log('order_type1', order_type);
      } else {
        order_type = 1;
        console.log('order_type2', order_type);
      }

      if (this.value2) {
        this.getDateData(this.value2.getTime() / 1000, type, order_type);
      } else {
        this.getDateData(type, order_type);
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.indexbox {
  font-size: 14px;

  .indexBoxLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
    background-color: #fff;

    .realtime {
      .realtimebot {
        margin-top: 20px;
        padding: 0 30px 23px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .listItem {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          .computed {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }

          & + .listItem {
            margin-top: 20px;
          }

          .topItem {
            flex-shrink: 0;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            // padding: 12px 20px 0;
            align-items: center;

            .today {
              text-align: left;

              p {
                font-size: 28px;
                font-weight: bold;
                line-height: 30px;
              }

              p:first-child {
                white-space: nowrap;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .indexBoxRight {
    flex: 10;
    min-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 100%;
    }

    .righttop {
      height: 388px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 8px;
      background-color: #fff;

      & > div {
        width: 100%;
        // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
      }

      .righttopT {
        height: 388px;
        padding: 16px 24px 20px 24px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;

        .titleBox {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;

          .title {
            font-size: 14px;
            font-weight: bold;
            color: #17171a;

            &:before {
              content: '';
              height: 14px;
              border-right: 3px solid #1467ff;
              margin-right: 8px;
            }
          }

          .ignoreall {
            color: #979899;
            cursor: pointer;
          }
        }

        .listBox {
          flex: 1;
          padding-right: 10px;
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
          }

          .itemBox {
            display: flex;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;

            .content {
              width: 192px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #656566;

              span {
                display: inline-block;
                width: 4px;
                height: 4px;
                margin-bottom: 2.5px;
                background: #1467ff;
                border-radius: 50%;
                margin-right: 5px;
              }
            }

            .time {
              color: #cacbcc;
              font-size: 12px;
            }

            .todeal {
              color: #1467ff;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.charts {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.realtimetop {
  display: flex;
  align-items: center;

  .title {
    font-weight: bold;
    font-size: 14px;
    padding: 20px 0;

    &:before {
      content: '';
      height: 14px;
      margin-right: 8px;
    }

    span,
    i {
      color: #409eff;
    }

    span {
      margin: 0 10px;
      font-size: 15px;
    }

    i {
      transform: rotate(90deg);
      font-size: 18px;
      cursor: pointer;
    }
  }

  .timeTxt {
    margin: 0 8px 0 16px;
    color: #969699;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .px {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 12px;
    }
  }
}
</style>
  